<template>
    <div class="Sale guaranteeAmount" v-loading="loading">
        <div class="header_Sale">
            <span class="el_left">生效时间</span>
            <div class="block el_left">
                <el-date-picker
                    v-model="periodDepartment.period.effective_time"
                    type="datetime"
                    placeholder="选择日期时间"
                    align="right"
                    :picker-options="pickerOptions"
                    :readonly="isReadOnly"
                    @change="modifyPeriod"
                >
                </el-date-picker>
            </div>
            <el-button
                class="el_left"
                type="primary"
                v-if="isReadOnly == false"
                @click="release"
            >
                发布
            </el-button>
            <el-select
                class="el_left"
                v-model="historyID"
                placeholder="请选择"
                @change="switchHistory"
            >
                <el-option
                    v-for="item in history"
                    :key="item.period"
                    :label="item.period"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-button
                type="primary"
                :loading="buttonLoading"
                class="el_left"
                v-if="isReadOnly && isNewest"
                @click="generateNewPeriods"
            >
                生成新期数
            </el-button>
        </div>
        <div class="quota">
            <div class="totalLimit el_left">
                <i class="iconfont iconzhangdanBill"></i>
                <p>销售部销售总信用额度</p>
                <div class="el_right">
                    <input
                        type="text"
                        :class="{'readOnly': isReadOnly}"
                        v-model="periodDepartment.period.total_amount"
                        :readonly="isReadOnly"
                        @change="modifyPeriod"
                    >
                    <span>万</span>
                </div>
            </div>
            <div class="surplusQuota el_right">
                <i class="iconfont iconyue"></i>
                <p>销售部剩余信用额度</p>
                <div class="el_right">
                    <strong style="color: #EB656F;">
                        {{ periodDepartment.period.surplus_amount }}
                    </strong>
                    <span>万</span>
                </div>
            </div>
        </div>
        <div class="quotaContent">
            <dl class="department el_left">
                <dt @click="addDepartment">
                    <i class="iconfont icontianjia1"></i>
                    添加部门
                </dt>
                <template v-for="(item, index) in periodDepartment.depts">
                    <dd :key="index" :class="{'active':deptIndex == index}" @click="selectDept(item,index)">
                        <div class="title">
                            <strong class="el_left">{{ item.dept_name }}一总信用额度</strong>
                            <span class="el_right">单位（万）</span>
                        </div>
                        <div class="quotaNumber">
                            <div>
                                <input
                                    type="text"
                                    v-model="item.total_amount"
                                    :readonly="isReadOnly"
                                    @change="modifyDept(item)"
                                >
                                <p>总信用额度</p>
                            </div>
                            <div>
                                <span>{{ item.surplus_amount }}</span>
                                <p>剩余信用额度</p>
                            </div>
                        </div>
                    </dd>
                </template>
            </dl>
            <div class="quotaList el_right" v-loading="quotaLoading">
                <h4>
                    说明：销售人员的担保额度在下表额度的基础上进行微调，调整额度为
                    <input
                        type="text"
                        v-model="adjustAmount"
                        :readonly="isReadOnly"
                        @change="modifyDept('adjustAmount')"
                    >%
                </h4>
                <div class="table currencyTable">
                    <template>
                        <el-table
                            stripe
                            :data="departmentStaff"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="user_name"
                                label="销售人员"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="user_post"
                                label="职务"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="total_amount"
                                label="信用额度（万）"
                            >
                                <template slot-scope="scope">
                                    <input
                                        type="text"
                                        v-model="scope.row.total_amount"
                                        :readonly="isReadOnly"
                                        @change="modifyPerson(scope.row)"
                                    >
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </div>
            </div>
        </div>
        <el-dialog
            title="添加部门"
            center
            :visible.sync="dialogVisible"
        >
            <ul class="departmentList">
                <template v-for="(item,index) in departmentData">
                    <li :key="index" :class="{'active': selectindex == index}" @click="selectdepartment(item,index)">
                        {{ item.Dept_Name }}
                    </li>
                </template>
            </ul>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAdd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import './css/saleStyle.styl';

export default {
    // 担保额度设置
    name: 'guarantee-amount',
    components: {},
    props: {},
    data() {
        return {
            // 页面加载状态
            loading: false,
            // 是否只读
            isReadOnly: false,
            // 生效时间
            // validTimeValue: '',
            // 期数数据集合
            history: [],
            // 生成新期数按钮loading状态
            buttonLoading: false,
            // 当前打开期数id
            historyID: '',
            // 是否是最新一期
            isNewest: true,
            // 当前期数发布状态 0未发布 1已发布
            status: 0,
            // 时间控件快捷选项
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    },
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    },
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    },
                }],
            },
            // 期次与部门数据
            periodDepartment: {
                period: {
                    id: '',
                    period: '',
                    period_code: 0,
                    total_amount: 0,
                    surplus_amount: 0,
                    effective_time: new Date(),
                    status: 0,
                },
                depts: [],
            },
            // 选中部门下标
            deptIndex: 0,
            // 选中部门数据
            deptData: {},
            // 选中部门id
            deptId: '',
            // 部门调整百分比
            adjustAmount: 0,
            // 部门下人员数据集合
            departmentStaff: [],
            // 部门下人员加载状态
            quotaLoading: false,
            // 新增部门数据集合
            departmentData: [],
            // 弹出层状态
            dialogVisible: false,
            // 选择部门下标
            selectindex: 0,
        };
    },
    created() {
        this.loading = true;
        // 拉取期数数据
        this.getPeriodsNumber();
        // 拉取新增部门数据集合
        this.getDepartmentData();
    },
    mounted() {},
    watch: {
        historyID(newVal) {
            if (newVal === this.history[0].id) {
                this.isNewest = true;
            } else {
                this.isNewest = false;
            }
            this.getPeriodDepartmentData();
        },
        deptId(newVal) {
            if (newVal !== '') {
                this.getDepartmentStaff();
            }
        },
        status(neeVal) {
            if (neeVal === 0) {
                this.isReadOnly = false;
            } else {
                this.isReadOnly = true;
            }
        },
    },
    computed: {},
    methods: {
        // 拉取期数数据
        getPeriodsNumber() {
            this.$axios
                .get('/interfaceApi/sale/sale_guarantee_amount/all_periods')
                .then(res => {
                    this.history = res;
                    this.historyID = res[0].id;
                    // 拉取当前期次与部门数据
                    this.getPeriodDepartmentData();
                    this.getDepartmentStaff();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 拉取当前期次与部门数据
        getPeriodDepartmentData() {
            this.$axios
                .get(`/interfaceApi/sale/sale_guarantee_amount/period/${this.historyID}`)
                .then(res => {
                    this.periodDepartment = res;
                    if (res.depts.length > 0) {
                    // 默认赋值第一个部门id
                        this.deptId = res.depts[this.deptIndex].dept_id;
                        this.deptData = res.depts[this.deptIndex];
                        this.adjustAmount = this.deptData.adjust_amount;
                    }
                    this.status = res.period.status;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 切换期数
        switchHistory() {
            this.loading = true;
            this.deptIndex = 0;
        },
        // 打开添加部门弹出层
        addDepartment() {
            if (this.isReadOnly === false) {
                this.dialogVisible = true;
            }
        },
        // 选择部门
        selectDept(data, index) {
            this.deptId = data.dept_id;
            this.deptData = data;
            this.adjustAmount = data.adjust_amount;
            this.deptIndex = index;
        },
        // 获取部门下人员数据
        getDepartmentStaff() {
            this.quotaLoading = true;
            this.$axios
                .get(`/interfaceApi/sale/sale_guarantee_amount/persons/${this.historyID}/${this.deptId}`)
                .then(res => {
                    this.quotaLoading = false;
                    this.departmentStaff = res;
                })
                .catch(error => {
                    this.quotaLoading = false;
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 获取新增部门
        getDepartmentData() {
            this.$axios
                .get('/interfaceApi/basicplatform/depts/depttypecode/B05-01')
                .then(res => {
                    this.departmentData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 选择新增部门
        selectdepartment(data, index) {
            this.selectindex = index;
        },
        // 确认添加部门
        confirmAdd() {
            const saveData = {
                dept: {
                    id: '',
                    period: this.periodDepartment.period.period,
                    period_id: this.periodDepartment.period.id,
                    dept_id: this.departmentData[this.selectindex].Dept_Id,
                    dept_name: this.departmentData[this.selectindex].Dept_Name,
                    adjust_amount: 0,
                    total_amount: 0,
                    surplus_amount: 0,
                    effective_time: new Date(),
                    status: 0,
                    sort_num: 0,
                },
            };
            this.dialogVisible = false;
            this.saveChanges(saveData, '添加成功!');
        },
        // 修改期数基本信息
        modifyPeriod() {
            const saveData = {
                period: {},
            };
            saveData.period = this.periodDepartment.period;
            this.saveChanges(saveData, '修改成功!');
        },
        // 修改部门数据
        modifyDept(data) {
            const saveData = {
                dept: {},
            };
            if (data === 'adjustAmount') {
                this.deptData.adjust_amount = this.adjustAmount;
                saveData.dept = this.deptData;
            } else {
                saveData.dept = data;
            }
            this.saveChanges(saveData, '修改成功!', 'modifyDept');
        },
        // 修改部门下人员数据
        modifyPerson(data) {
            const saveData = {
                person: {},
            };
            saveData.person = data;
            this.saveChanges(saveData, '修改成功!', 'modifyPerson');
        },
        // 保存修改
        saveChanges(saveData, text, type) {
            this.$axios
                .post('/interfaceApi/sale/sale_guarantee_amount/save', saveData)
                .then(() => {
                    this.loading = false;
                    this.getPeriodDepartmentData();
                    this.$message({
                        message: text,
                        type: 'success',
                    });
                })
                .catch(error => {
                    if (type === 'modifyDept') {
                        this.getPeriodDepartmentData();
                    } else if (type === 'modifyPerson') {
                        this.getDepartmentStaff();
                    }
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 发布
        release() {
            this.loading = true;
            this.$axios
                .post(`/interfaceApi/sale/sale_guarantee_amount/period/publish?id=${this.historyID}`)
                .then(() => {
                    this.loading = false;
                    this.getPeriodDepartmentData();
                    this.$message({
                        message: '发布成功！',
                        type: 'success',
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 生成新期数
        generateNewPeriods() {
            this.loading = true;
            this.$axios
                .post('/interfaceApi/sale/sale_guarantee_amount/addperiod')
                .then(() => {
                    this.getPeriodsNumber();
                    this.$message({
                        message: '生成成功！',
                        type: 'success',
                    });
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
</style>
